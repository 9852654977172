import React from 'react';
import { Link } from 'gatsby';

import './style.sass';

export default props => {
  const handleClick = e => {
    e.preventDefault();
    if (!props.handleClick) {
      return;
    }
    props.handleClick();
  };

  return !!props.to ? (
    <Link to={props.to} className="button">
      {props.label}
    </Link>
  ) : (
    <button className="button" onClick={handleClick}>
      {props.label}
    </button>
  );
};
