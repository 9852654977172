import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';

import './style.sass';

export default ({ location, title }) => {
  console.log(title);
  return (
    <div className="snsShare">
      <div className="snsShare__message">
        <span>友達に教えてあげよう！</span>
      </div>
      <div className="snsShareButtons">
        <div className="snsShareButtons__item">
          <FacebookShareButton url={location.href} className="mr-2">
            <FacebookIcon size={40} round />
          </FacebookShareButton>
        </div>
        <div className="snsShareButtons__item">
          <TwitterShareButton
            title={title}
            url={location.href}
            className="mr-2"
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
        <div className="snsShareButtons__item">
          <LineShareButton title={title} url={location.href} className="mr-2">
            <LineIcon size={40} round />
          </LineShareButton>
        </div>
      </div>
    </div>
  );
};
