import React from 'react';
import './style.sass';

export default ({ children, type }) => {
  const classes = type ? `title title--${type}` : 'title';
  return (
    <div className={classes}>
      <h2>{children}</h2>
    </div>
  );
};
